import API from "../../../api/index";

export default {
  alipay: params => {
    return API.requestGet(
      {
        url: "/api/merchant/pay/recharge",
        params,
        auth: true
      },
      false
    );
  }
};
